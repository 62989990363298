import request from "./request";

const MCQExamService = {
  GetAll: async (id, context) => {
    return await request.sendRequest(`/mcqexam/all/${id}`, context.token);
  },
  Start: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/start/${id}`, context);
  },
  Cancel: async (payload, context) => {
    return await request.sendPostRequest(`/mcqexam/cancel`, payload, context);
  },
  CalculateScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/calculate/${id}`, context);
  },
  RecalculateScore: async (id, context) => {
    return await request.sendPutRequest(`/mcqexam/re-calculate/${id}`, context);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/mcqexam`, payload, context);
  },
};

export default MCQExamService;

import request from "./request";

const ReportService = {
  getQuizReport: async (id, context) => {
    return await request.sendRequest(`/quizreport/all/${id ?? 0}`, context.token);
  },
  getAssessmentReport: async (id, context) => {
    return await request.sendRequest(`/assessmentreport/all/${id ?? 0}`, context.token);
  },
};

export default ReportService;

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import Banner from "./banner";
import AuthContext from "../auth/auth-context";
import "./layout.css";
import CatalogueService from '../services/catalogue.service'

const Layout = (props) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const [hideSideBar, setHideSideBar] = useState(false);
  const [hasIncidents, setHasIncidents] = useState(false);

  const catelogueAccess = authContext.getPermissions("catalogues");
  const courseAccess = authContext.getPermissions("courses");
  const bayAccess = authContext.getPermissions("bays");
  const assessmentAccess = authContext.getPermissions("assessments");
  const reportAccess = authContext.getPermissions("report");
  const supportAccess = authContext.getPermissions("support");
  const documentAccess = authContext.getPermissions("documents");
  const quizAccess = authContext.getPermissions("quiz");

  const showCatalogue =
    catelogueAccess.filter((f) => f.access.includes(1)).length > 0;
  const showCourse = courseAccess.filter((f) => f.access.includes(1)).length > 0;
  const showBay = bayAccess.filter((f) => f.access.includes(1)).length > 0;
  const showAssessment =
    assessmentAccess.filter((f) => f.access.includes(1)).length > 0;
  const showReport = reportAccess.filter((f) => f.access.includes(1)).length > 0;
  const showSupport = supportAccess.filter((f) => f.access.includes(1)).length > 0;
  const showDocument = documentAccess.filter((f) => f.access.includes(1)).length > 0;
  const showQuiz = quizAccess.filter((f) => f.access.includes(1)).length > 0;

  const validateHideSideBar = useCallback(() => {
    setHideSideBar(false);
    const paths = [
      "/qrcode",
      "/configureqr",
      "/mfa",
      "/mfaq",
      "/verifyotp",
      "/configmfaotp",
      "/questions",
      "/reset",
      "/logout",
      "/profile",
      "/profile/edit",
      "/change",
      "/securityanswers",
    ];
    let path = location.pathname;
    if (paths.includes(path) || path.startsWith("/configureqr")) {
      setHideSideBar(true);
    }
  }, [location]);

  useEffect(() => {
    validateHideSideBar();
    async function fetchData() {
      let result = await CatalogueService.HasIncidents(authContext);
      if (result !== undefined && result !== null && result.isSuccess) {
        setHasIncidents(result.data);
      } else {
        setHasIncidents(false);
      }
    }
    fetchData();
  }, [validateHideSideBar, authContext, location]);

  return (
    <Fragment>
      {authContext.isLoggedIn &&
        authContext.isAnswered &&
        authContext.mfaSetupCompleted &&
        authContext.mfaVerified &&
        authContext.resetPassword === false && (
          <Banner className={hideSideBar ? "position-fixed" : ""} />
        )}
      {hideSideBar === false && (
        <div className="wrapper">
          <div className="sidebar-wrapper">
            <div className="menu-icon-holder">
              <a href={authContext.onGoingValidations ? "#" : "#/dashboard"} title="Home">
                <img src="assets/img/Home.png" alt="Home"></img>
              </a>
            </div>
            {
              showCourse &&
              <div className="menu-icon-holder">
                <a href={authContext.onGoingValidations ? "#" : "#/courses"} title="Courses">
                  <img src="assets/img/Course.png" alt="Courses"></img>
                </a>
              </div>
            }
            {
              showDocument &&
              <div className="menu-icon-holder">
                <a href={authContext.onGoingValidations ? "#" : "#/doctypes"} title="Documents">
                  <img src="assets/img/Document.png" alt="Documents"></img>
                </a>
              </div>
            }
            {
              showCatalogue && hasIncidents &&
              <div className="menu-icon-holder">
                <a href={authContext.onGoingValidations ? "#" : "#/types"} title="Catalogues">
                  <img src="assets/img/Catalogue.png" alt="Catalogues"></img>
                </a>
              </div>
            }
            {
              showBay &&
              <div className="menu-icon-holder">
                {/* <a target="_blank" rel="noreferrer" href="https://cybersecurity.nuvepro.io/"> */}
                <a href={authContext.onGoingValidations ? "#" : "#/bays"} title="Cyber Range">
                  <img src="assets/img/Bay.png" alt="Bays"></img>
                </a>
              </div>
            }
            {
              showSupport &&
              <div className="menu-icon-holder">
                <a href="#/support" title="Support">
                  <img src="assets/img/Support.png" alt="Support"></img>
                </a>
              </div>
            }
            {
              showAssessment &&
              <div className="menu-icon-holder">
                <a href="#/assessments" title="Assessments">
                  <img src="assets/img/Assessment.png" alt="Assessments"></img>
                </a>
              </div>
            }
            {
              showReport &&
              <div className="menu-icon-holder">
                <a href={authContext.onGoingValidations ? "#" : "#/reports"} title="Reports">
                  <img src="assets/img/Report.png" alt="Reports"></img>
                </a>
              </div>
            }
            {
              showQuiz &&
              <div className="menu-icon-holder">
                <a href="#/quizzes" title="Quiz">
                  <img src="assets/img/quizl.png" alt="Quiz"></img>
                </a>
              </div>
            }
          </div>
          <div className="page-content-wrapper">{props.children}</div>
        </div>
      )}
      {hideSideBar && <div>{props.children}</div>}
    </Fragment>
  );
};

export default Layout;

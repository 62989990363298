import request from "./request";

const AssessmentMasterService = {
  GetAll: async (context) => {
    return await request.sendRequest(`/assessmentmaster/all`, context.token);
  },
  Get: async (id, context) => {
    return await request.sendRequest(`/assessmentmaster/${id}`, context.token);
  },
  GetAttendees: async (id, searchText, context) => {
    return await request.sendRequest(`/assessmentmaster/attendees/-1/0/${id}/${searchText}`, context.token);
  },
  Save: async (payload, context) => {
    return await request.sendPostRequest(`/assessmentmaster`, payload, context);
  },
  Remove: async (id, context) => {
    return await request.sendDeleteRequest(`/assessmentmaster/${id}`, context.token);
  },
};

export default AssessmentMasterService;

import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../../auth/auth-context";
import AssessmentMasterService from '../../../../services/assessmentmaster.service'
import AlertService from "../../../../services/alert.service";
import Card from "./card";

const MCQAttendees = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [attendees, setAttendees] = useState([]);
    const [searchText, setSearchText] = useState('');
    const authContext = useContext(AuthContext);

    const assessmentAccess = authContext.getPermissions("assessments");
    if (assessmentAccess.filter((f) => f.access.includes(1)).length === 0 || authContext.onGoingValidations) {
        navigate("/");
    }

    const loadAttendees = useCallback(async () => {
        setLoading(true);
        const data = await AssessmentMasterService.GetAttendees(params.id, searchText, authContext);
        if (data.isSuccess) {
            setLoading(false);
            let i = 0;
            data.data.results.forEach((element) => {
                i++;
                element.no = i;
            });
            setAttendees(data.data.results);
        } else {
            setLoading(false);
            AlertService.error(data.errorMessage);
        }
    }, [authContext, searchText, params.id]);

    useEffect(() => {
        loadAttendees();
    }, [loadAttendees, authContext]);

    const onCardClick = async (id) => {
        navigate(`/master/assessments/attendees/view/${id}`);
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3">
                        <div className="form-group has-search search-group catalogue-search">
                            <span className="fa fa-search form-control-feedback"></span>
                            <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} className="form-control" placeholder="Search" />
                            {searchText && <span className="input-group-append">
                                <button className="btn border-0" type="button" onClick={() => setSearchText('')}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </span>}
                        </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-6 course-title">
                        <button type="button" className="btn-incident-back" onClick={() => navigate(`/master/assessments/view/${params.id}`)}>
                            <i className="fa fa-chevron-circle-left"></i> <span>Back</span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    {!loading &&
                        attendees &&
                        attendees.map((attendee, i) => (
                            <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                <Card
                                    id={attendee.id}
                                    data={attendee}
                                    clickHandler={() => onCardClick(attendee.id)}
                                ></Card>
                            </div>
                        ))}
                    {!loading && attendees && attendees.length === 0 && (
                        <div className="col-12">No data available...</div>
                    )}
                    {loading && <div className="col-12">Loading...</div>}
                </div>
            </div>
        </Fragment>
    );
};

export default MCQAttendees;
